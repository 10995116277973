<template>
  <v-dialog
    v-model="dialog"
    width="600"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        flat
        color="primary"
        class="rounded-lg mt-3"
        v-on="on"
        v-bind="attrs"
        >
        <v-card-text
          class="white--text text-uppercase font-weight-medium"
          >
          <v-icon color="white" class="mt-n1 mr-1">mdi-plus-circle</v-icon> Crear nueva feria QR
        </v-card-text>
      </v-card>
    </template>

    <v-card
      class="rounded-lg"
      >
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialog = false"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="px-6 pb-6"
        >
        <div
          class="text-center text-h5 text--primary font-weight-medium"
          >
          CREAR FERIA
        </div>

        <div
          class="text-subtitle-1 text-center"
          >
          Para comenzar solo necesitamos los datos básicos de la feria. No te preocupes, podrás modificar estos datos más adelante.
        </div>

        <div
          class="my-3 mx-auto primary"
          style="height: 4px; width: 100px;"
          ></div>

        <div
          >
          <v-form
            v-model="valid"
            ref="form"
            lazy-validation
            class="d-flex flex-column"
            style="gap: 4px"
            >
            <v-text-field
              v-model="attributes.name"
              outlined
              class="rounded-lg"
              :rules="required"
              label="Nombre de la feria"
              dense
              ></v-text-field>

            <v-text-field
              v-model="attributes.owner"
              outlined
              class="rounded-lg"
              :rules="required"
              label="Individuo o entidad a cargo"
              hint="¿Quién llevará adelante la feria?"
              dense
              ></v-text-field>

            <v-text-field
              v-model="attributes.commission"
              type="number"
              outlined
              class="rounded-lg"
              :rules="required"
              label="Comisión por venta"
              dense
              ></v-text-field>

            <div
              class="d-flex flex-column flex-md-row"
              :style="{ gap: $vuetify.breakpoint.mdAndUp ? '12px' : '4px' }"
              >
              <v-menu
                v-model="startsAtMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="attributes.startsAt"
                    label="Fecha de inicio"
                    outlined
                    dense
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    :rules="required"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>

                <v-date-picker
                  v-model="attributes.startsAt"
                  @input="startsAtMenu = false"
                  ></v-date-picker>
              </v-menu>

              <v-menu
                v-model="endsAtMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="attributes.endsAt"
                    label="Fecha de fin"
                    outlined
                    dense
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    :rules="required"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>

                <v-date-picker
                  v-model="attributes.endsAt"
                  @input="endsAtMenu = false"
                  ></v-date-picker>
              </v-menu>
            </div>

            <v-textarea
              v-model="attributes.description"
              outlined
              class="rounded-lg"
              :rules="required"
              label="Descripción"
              hint="Cuéntanos un poco más sobre tu feria y de qué se trata."
              dense
              ></v-textarea>

            <v-btn
              block
              color="primary"
              depressed
              block
              class="rounded-lg"
              @click="createFair"
              >
              Confirmar
            </v-btn>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { CreateFair } from '@/graphql/mutations/fairs/fairs'

export default {
  data: () => ({
    dialog: false,
    valid: true,
    startsAtMenu: false,
    endsAtMenu: false,
    required: [
      v => !!v || 'Campo obligatorio'
    ],
    attributes: {
      name: null,
      owner: null,
      startsAt: null,
      endsAt: null,
      description: null,
      commission: null,
    }
  }),

  methods: {
    createFair () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: CreateFair,
          variables: {
            input: {
              attributes: this.attributes
            }
          }
        }).then ( res => {
          this.$router.push({ name: 'fairs', params: { id: res.data.createFair.id }})
        })
      }
    }
  }
}
</script>
